
import { Options, Vue } from 'vue-class-component';

import Card from '@/components/Card.vue'; // @ is an alias to /src

// 编译后代码
// import { Button } from 'vant';
// import 'vant/es/button/style';
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

// import { Swiper } from 'swiper';
// Import Swiper styles
// import 'swiper/css';


@Options({
  components: {
    Card
  },
  data() {
    return { 
      list: [
        {
          "image": require("@/assets/populer1.png"),
          "name": "糖醋里脊",
          "desc": "#Bland diet #Shaping",
          "zan": 10,
          "view": 297
        },
        {
          "image": require("@/assets/populer2.png"),
          "name": "糖醋里脊",
          "desc": "#Bland diet #Shaping",
          "zan": 10,
          "view": 297
        },
      ]
    }
  }
})
export default class Collection extends Vue {

}
