
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    list: Array
  },
  data() {
    return { 
      tab: this.list[0]
    }
  },
  methods: {
    clickMenu(event:unknown) {
      console.log(event);
      this.tab = event
    }
  }
  
})
export default class Menu extends Vue {
  msg!: string
}
